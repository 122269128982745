import React, { Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import AuthRoute from '../../components/AuthRoute';
import PrivatePaths from '../ProtectedRoutes';
import PublicPaths from '../PublicRoutes';
import AuthPaths from '../AuthRoutes';
import Layout from '../../components/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from '../../redux/reducers/profileSlice';
import { Roles } from '../../enums';
import Preloader from '../../components/Preloader';
import Restrict from '../../pages/Restrict';
import { fetchProjectProfileData } from '../../redux/reducers/projectProfileSlice';


interface RouteItem {
  path: string;
  Content: React.ComponentType<any>;
}

const RoutesHOC: React.FC = () => {
  const [restrictPrivatePaths,setRestrictPrivatePaths] = useState<any>([]);
 const [restrictPublicPaths,setRestrictPublicPaths] = useState<any>([])
  const profile = useSelector((state : any) => state.profileSlice.profile);
  const projectProfile = useSelector((state : any) => state.projectProfileSlice.projectProfile);
  const loading = useSelector((state : any) => state.profileSlice.loading);
  const projectLoading = useSelector((state : any) => state.projectProfileSlice.loading);
  const category = profile?.data?.userType

const accessConfigurations = profile?.data?.accessConfigurations;

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProfileData() as any);
    dispatch(fetchProjectProfileData() as any);
  },[])
  useEffect(()=>{
    filterAdminRoutes()
 
 
// filterRoutes();
  },[loading,projectLoading])
  const filterAdminRoutes = () => {
    let newPublicArr:any =[];
    let findPublicRoutes = PublicPaths.find((item:any)=>item?.path === '/sign-up');
    newPublicArr.push(findPublicRoutes);
    setRestrictPublicPaths(newPublicArr);
    if(profile?.data)
    {
      if (category?.includes('superAdmin')) {
        setRestrictPrivatePaths(PrivatePaths);
      } else {
        let index = PrivatePaths.findIndex(item => item.path === '/super-admin-dashboard');
        let newArr = [...PrivatePaths]; // Create a copy of PrivatePaths array
       newPublicArr = [...PublicPaths];
        
        newArr[index] = {
          path: '/super-admin-dashboard',
          Content: () => (
            <Suspense fallback={<Preloader/>}>
              <Restrict />
            </Suspense>
          )
        }
        index = PrivatePaths.findIndex(item => item.path === '/all-admins');
        newArr[index] = {
          path: '/all-admins',
          Content: () => (
            <Suspense fallback={<Preloader/>}>
              <Restrict />
            </Suspense>
          )
        }
     if(!category?.includes('admin'))
     {
      index = PrivatePaths.findIndex(item => item.path === '/access-config');
      newArr[index] = {
        path: '/access-config',
        Content: () => (
          <Suspense fallback={<Preloader/>}>
            <Restrict />
          </Suspense>
        )
      }
      index = PrivatePaths.findIndex(item => item.path === '/default-config');
      newArr[index] = {
        path: '/default-config',
        Content: () => (
          <Suspense fallback={<Preloader/>}>
            <Restrict />
          </Suspense>
        )
      }
     }
     accessConfigurations && Object.keys(accessConfigurations).map((key)=>{
      switch (key) {
        case 'appointment':
          if(!accessConfigurations[key]?.view_module){
            index = PrivatePaths.findIndex(item => item.path === '/appointment-dashboard');
            newArr[index] = {
              path: '/appointment-dashboard',
              Content: () => (
                <Suspense fallback={<Preloader/>}>
                  <Restrict />
                </Suspense>
              )
            }
            index = PrivatePaths.findIndex(item => item.path === '/patient-registration');
            newArr[index] = {
              path: '/patient-registration',
              Content: () => (
                <Suspense fallback={<Preloader/>}>
                  <Restrict />
                </Suspense>
              )
            }
            index = PrivatePaths.findIndex(item => item.path === '/user-reports');
            newArr[index] = {
              path: '/user-reports',
              Content: () => (
                <Suspense fallback={<Preloader/>}>
                  <Restrict />
                </Suspense>
              )
            }
            index = PrivatePaths.findIndex(item => item.path === '/delete-history');
            newArr[index] = {
              path: '/delete-history',
              Content: () => (
                <Suspense fallback={<Preloader/>}>
                  <Restrict />
                </Suspense>
              )
            }
          }
          if(!accessConfigurations[key]?.add_appointment)
          {
            index = PrivatePaths.findIndex(item => item.path === '/patient-registration');
            newArr[index] = {
              path: '/patient-registration',
              Content: () => (
                <Suspense fallback={<Preloader/>}>
                  <Restrict />
                </Suspense>
              )
            }
          }
            break;
    break;
            // case 'registration':
            //   if(!accessConfigurations[key]?.view_module || !accessConfigurations[key]?.edit_patient){
            //     index = PrivatePaths.findIndex(item => item.path === '/update-patient');
            //     newArr[index] = {
            //       path: '/update-patient',
            //       Content: () => (
            //         <Suspense fallback={<Preloader/>}>
            //           <Restrict />
            //         </Suspense>
            //       )
            //     }
             
            //   }
            //     break;
                // case 'registration':
                //   if(!accessConfigurations[key]?.view_module){
                //     index = PrivatePaths.findIndex(item => item.path === '/patient-registration');
                //     newArr[index] = {
                //       path: '/patient-registration',
                //       Content: () => (
                //         <Suspense fallback={<Preloader/>}>
                //           <Restrict />
                //         </Suspense>
                //       )
                //     }
                //   }
                //     break;
                    case 'employee':
                      if(!accessConfigurations[key]?.view_module){
                        index = PrivatePaths.findIndex(item => item.path === '/employe');
                        newArr[index] = {
                          path: '/employe',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                        index = PrivatePaths.findIndex(item => item.path === '/employe-category');
                        newArr[index] = {
                          path: '/employe-category',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                        index = PrivatePaths.findIndex(item => item.path === '/employe-add');
                        newArr[index] = {
                          path: '/employe-add',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                        index = PrivatePaths.findIndex(item => item.path === '/add-department');
                        newArr[index] = {
                          path: '/add-department',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                      if(!accessConfigurations[key]?.view_category)
                      {
                        index = PrivatePaths.findIndex(item => item.path === '/employe-category');
                        newArr[index] = {
                          path: '/employe-category',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                        if(!accessConfigurations[key]?.add_employee)
                      {
                        index = PrivatePaths.findIndex(item => item.path === '/employe-add');
                        newArr[index] = {
                          path: '/employe-add',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                      if(!accessConfigurations[key]?.view_department)
                      {
                        index = PrivatePaths.findIndex(item => item.path === '/add-department');
                        newArr[index] = {
                          path: '/add-department',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                      if(!accessConfigurations[key]?.edit_employee)
                      {
                        index = PrivatePaths.findIndex(item => item.path === '/employe-add/:id');
                        newArr[index] = {
                          path: '/employe-add/:id',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                      if(!accessConfigurations[key]?.attachment_files)
                      {
                        index = PrivatePaths.findIndex(item => item.path === '/attatch-file/:id/:name');
                        newArr[index] = {
                          path: '/attatch-file/:id/:name',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                      if(!accessConfigurations[key]?.employee_details)
                      {
                        index = PrivatePaths.findIndex(item => item.path === '/add-employe-details/:id/:name');
                        newArr[index] = {
                          path: '/add-employe-details/:id/:name',
                          Content: () => (
                            <Suspense fallback={<Preloader/>}>
                              <Restrict />
                            </Suspense>
                          )
                        }
                      }
                        break;
                        // case 'counter':
                        //   if(!accessConfigurations[key]?.view_module){
                        //     index = PublicPaths.findIndex(item => item.path === '/counter');
                        //     newPublicArr[index] = {
                        //       path: '/counter',
                        //       Content: () => (
                        //         <Suspense fallback={<Preloader/>}>
                        //           <Restrict />
                        //         </Suspense>
                        //       )
                        //     }
                        //   }
                        //     break;
                            case 'insurance':
                              if(!accessConfigurations[key]?.view_module){
                                index = PrivatePaths.findIndex(item => item.path === '/insurances');
                                newArr[index] = {
                                  path: '/insurances',
                                  Content: () => (
                                    <Suspense fallback={<Preloader/>}>
                                      <Restrict />
                                    </Suspense>
                                  )
                                }
                                index = PrivatePaths.findIndex(item => item.path === '/companies');
                                newArr[index] = {
                                  path: '/companies',
                                  Content: () => (
                                    <Suspense fallback={<Preloader/>}>
                                      <Restrict />
                                    </Suspense>
                                  )
                                }
                                index = PrivatePaths.findIndex(item => item.path === '/insurance-services');
                                newArr[index] = {
                                  path: '/insurance-services',
                                  Content: () => (
                                    <Suspense fallback={<Preloader/>}>
                                      <Restrict />
                                    </Suspense>
                                  )
                                }
                                index = PrivatePaths.findIndex(item => item.path === '/insurance-reports');
                                newArr[index] = {
                                  path: '/insurance-reports',
                                  Content: () => (
                                    <Suspense fallback={<Preloader/>}>
                                      <Restrict />
                                    </Suspense>
                                  )
                                }
                              }
                              if(!accessConfigurations[key]?.view_companies)
                               {
                                index = PrivatePaths.findIndex(item => item.path === '/companies');
                                newArr[index] = {
                                  path: '/companies',
                                  Content: () => (
                                    <Suspense fallback={<Preloader/>}>
                                      <Restrict />
                                    </Suspense>
                                  )
                                }
                               }
                             if(!accessConfigurations[key]?.view_services)
                             {
                              index = PrivatePaths.findIndex(item => item.path === '/insurance-services');
                              newArr[index] = {
                                path: '/insurance-services',
                                Content: () => (
                                  <Suspense fallback={<Preloader/>}>
                                    <Restrict />
                                  </Suspense>
                                )
                              }
                             }
                               if(!accessConfigurations[key]?.view_reports)
                               {
                                index = PrivatePaths.findIndex(item => item.path === '/insurance-reports');
                                newArr[index] = {
                                  path: '/insurance-reports',
                                  Content: () => (
                                    <Suspense fallback={<Preloader/>}>
                                      <Restrict />
                                    </Suspense>
                                  )
                                }
                               }
                                break;
                                case 'reports':
                                  if(!accessConfigurations[key]?.view_module){
                                    index = PrivatePaths.findIndex(item => item.path === '/user-reports');
                                    newArr[index] = {
                                      path: '/user-reports',
                                      Content: () => (
                                        <Suspense fallback={<Preloader/>}>
                                          <Restrict />
                                        </Suspense>
                                      )
                                    }
                                  }
                                    break;
                                    case 'deleteHistory':
                                      if(!accessConfigurations[key]?.view_module){
                                        index = PrivatePaths.findIndex(item => item.path === '/delete-history');
                                        newArr[index] = {
                                          path: '/delete-history',
                                          Content: () => (
                                            <Suspense fallback={<Preloader/>}>
                                              <Restrict />
                                            </Suspense>
                                          )
                                        }
                                      }
                                        break;
     
        default:
          // setRestrictPrivatePaths(PrivatePaths);
            break;
      }
     })
let opdPaths = ['/opd-admin', '/add-new-medicine', '/opd-dashboard', '/add-patient-record/:id', '/diagnosis', '/procedure', '/symptoms', '/case-dashboard', '/add-case', '/add-case/:id', '/generic', '/add-vitals', '/add-schedule', '/adminstration'];
let pathologyPaths =   [
    '/pathology',
    '/add-new-test',
    '/add-new-test/:id',
    '/depart-units',
    '/depart-units-1',
    '/pathology-register',
    '/pathology-registration',
    '/patient-registration-and-test-entry',
    '/patient-registration-and-test-entry/:id',
    '/departments-and-locations',
    '/stock-management',
    '/add-assets',
    '/pathology-analysis',
    '/add-test-result/:testId/:id',
    '/test-result'
  ]
  let registrationPaths = [
      '/appointment-dashboard',
      '/patient-registration',
      '/patient-registration:appointment',
      '/employe',
      '/employe-category',
      '/employe-add',
      '/employe-add/:id',
      '/add-shift/:id/:name',
      '/add-employe-details/:id/:name',
      '/add-department',
      '/user-reports',
      '/insurances',
      '/companies',
      '/attatch-file/:id/:name',
      '/emp-services/:empId/:name',
      '/add-new-company',
      '/insurance-services',
      '/report-table',
      '/insurance-reports',
      // '/access-config',
      // '/user-access/:id/:name',
      // '/default-config',
      '/',
      '/delete-history'
    ]
  if (!projectProfile?.departmentsAccess.includes('opd')) {
    opdPaths.map((item2)=>{
    
      let index = newArr.findIndex((item:any) => item.path === item2);
     
      if (index !== -1) {
        newArr[index] = {
          path: item2,
          Content: () => (
            <Suspense fallback={<Preloader />}>
              <Restrict />
            </Suspense>
          )
        };
      }
    })
   
  }
  if (!projectProfile?.departmentsAccess.includes('pathology')) {
    pathologyPaths.map((item2)=>{
    
      let index = newArr.findIndex((item:any) => item.path === item2);
     
      if (index !== -1) {
        newArr[index] = {
          path: item2,
          Content: () => (
            <Suspense fallback={<Preloader />}>
              <Restrict />
            </Suspense>
          )
        };
      }
    })
   
  }
  if (!projectProfile?.departmentsAccess.includes('registration')) {
    registrationPaths.map((item2)=>{
    
      let index = newArr.findIndex((item:any) => item.path === item2);
     
      if (index !== -1) {
        newArr[index] = {
          path: item2,
          Content: () => (
            <Suspense fallback={<Preloader />}>
              <Restrict />
            </Suspense>
          )
        };
      }
    })
   
  }
     setRestrictPublicPaths(newPublicArr);
        setRestrictPrivatePaths(newArr);
  
       
      }
    }else{
      let routesPrivateArr = []
      routesPrivateArr = PrivatePaths?.map((item,index)=>{
          return {
            path: item?.path,
            Content: () => (
              <Suspense fallback={<Preloader/>}>
                <Preloader />
              </Suspense>
            )
          }
        })
        let routesPublicArr = [];
        routesPublicArr = PublicPaths?.map((item,index)=>{
          return {
            path: item?.path,
            Content: () => (
              <Suspense fallback={<Preloader/>}>
                <Preloader />
              </Suspense>
            )
          }
        })
        setRestrictPrivatePaths(routesPrivateArr);
        setRestrictPublicPaths(routesPublicArr);
    }
    
  }
 

  return (
    <>
      <Routes>
        <Route element={<AuthRoute />}>
          {AuthPaths.map((route: RouteItem) => (
            <Route key={route.path} path={route.path} element={<route.Content />} />
          ))}
        </Route>
        {PublicPaths.map((route: RouteItem) => (
          <Route key={route.path} path={route.path} element={<route.Content />} />
        ))}
        <Route element={<PrivateRoute />}>
          {restrictPrivatePaths?.map((route: RouteItem) => (
            <Route key={route.path} path={route.path} element={<Layout><route.Content /></Layout>} />
          ))}
        </Route>
        
      </Routes>
    </>
  );
};

export default RoutesHOC;