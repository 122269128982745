import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InputMask from 'react-input-mask';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteConfirmationModal from '../../../Modals/delete-modal';
import DynamicTable from '../../../Dynamic-table';
import { useNavigate } from 'react-router-dom';




const MedicineAdministration: React.FC<any> = () => {

    const [editData, setEditData] = useState(null); // State to hold data being edited
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState<any>('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState('');
    const [medicineTypeData, setMedicineTypeData] = useState<any>()
    const [searchInput, setSearchInput] = useState('');
    const [formData, setFormData] = useState({
        RA_Medical_Term: '',
        RA_Abbreviation: '',

    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const navigate = useNavigate()
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/getAllByPagination`, {
                params: {
                    pageNo: page + 1,
                    count: rowsPerPage,
                    search: searchInput
                },
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setMedicineTypeData(response.data?.data)
            setTotal(response.data?.totalCount)
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Error fetching data');
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditClick = (id: any, rowData: any) => {
        setEditData(id); // Set data being edited
        setFormData({
            RA_Medical_Term: rowData.RA_Medical_Term,
            RA_Abbreviation: rowData.RA_Abbreviation,

        });
    };

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, searchInput]);

    const handleSubmit = async () => {
        if (editData) {
            try {
                const response = await axios.patch(
                    `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/updateById/${editData}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                if (response.data.isSuccess === false) {
                    toast.error(response.data.message);
                } else {
                    toast.success(response.data.message);
                    setFormData({
                        RA_Medical_Term: '',
                        RA_Abbreviation: '',
                    });
                    fetchData();
                    setEditData(null);
                }
            } catch (error: any) {
                toast.error(error.message);
            }
        } else {
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/addAdministrationRout`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }
                );
                if (response.data.isSuccess === false) {
                    toast.error(response.data.message);
                } else {
                    toast.success(response.data.message);
                    setFormData({
                        RA_Medical_Term: '',
                        RA_Abbreviation: '',
                    });
                    fetchData();
                    setEditData(null);
                }
            } catch (error: any) {
                toast.error(error.message);
            }
        }
    };


    const handleDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDeleteClick = (id: any) => {
        setIsDeleteModalOpen(true);
        setSelectedItemId(id);
    };

    const handleDeleteConfirm = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Authorization token is missing');
                return;
            }

            const response = await axios.delete(
                `${process.env.REACT_APP_OPD_API_URL}/routes-of-administration/deleteById/${selectedItemId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            fetchData()
            toast.success(response.data.message);
        } catch (error: any) {
            toast.error(error.message);
        }
        setIsDeleteModalOpen(false); // Close the modal after deletion
    };

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(e.target.value);
    };

    return (
        <Box>
            <Box sx={{ background: '#fff', p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant='h5' sx={{ fontWeight: '600', width: '70%' }}>Add Route Administration</Typography>
                <Box display='flex' justifyContent='space-between' alignItems='center' width='40%' gap={1}>
                    <TextField
                        label='Search Abbreviations'
                        variant="outlined"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        fullWidth
                        size="small"
                        InputProps={{
                            sx: { borderRadius: 30 },
                        }}
                        sx={{ width: '100%' }}
                    />
                    <CancelOutlinedIcon onClick={() => navigate('/add-new-medicine')} sx={{ cursor: 'pointer' }} />
                </Box>
            </Box>

            <Box mt={2}>
                <Grid container spacing={2}>
                    <Grid item md={4} sm={12} xs={12}>
                        <Box sx={{ background: '#fff', p: 2, borderRadius: '4px', minHeight: '65vh', position: 'relative', display: 'flex', flexDirection: 'column', gap: 1 }}>

                            <Typography variant='h6' sx={{ mb: 2 }}>Route Administration</Typography>
                            {/* <InputMask
                                mask="9-9-9"
                                value={schedule}
                                onChange={(e: any) => setSchedule(e.target.value)}
                                maskChar=" "
                            >
                                {
                                    //@ts-ignore
                                    () =>

                                        <TextField sx={{ '& .MuiInputBase-input': { height: '10px ', fontSize: '14px' } }} fullWidth placeholder='Enter Schedule' />}
                            </InputMask> */}
                            <TextField size="small"
                                placeholder='Enter Abr Notation'
                                name='RA_Abbreviation'
                                value={formData.RA_Abbreviation}
                                fullWidth
                                required
                                onChange={handleChange}
                            />

                            <TextField size="small"
                                placeholder='Enter Eng Notation'
                                name='RA_Medical_Term'
                                value={formData.RA_Medical_Term}
                                fullWidth
                                required
                                onChange={handleChange}
                            />



                            <Box display='flex' alignItems='center' gap={1} position='absolute' top='80%' right='10%'>
                                <Button variant='contained' onClick={handleSubmit}>{editData ? 'Update' : 'Save'}</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <DynamicTable
                            headers={["SN", "RA Abberivation", "RA Medical term", "Action"]}
                            data={medicineTypeData?.map((item: any, index: any) => [
                                (page * rowsPerPage) + index + 1,
                                item?.RA_Abbreviation,
                                item?.RA_Medical_Term,

                            ])}
                            loading={false}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            totalRows={total} // Update this based on the total count from the API response
                            actionButtons={[
                                {
                                    icon: <EditIcon sx={{ color: '#FFB800', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(133, 96, 1)' } }} />,
                                    onClick: (rowIndex: any) => handleEditClick(medicineTypeData[rowIndex]?._id, medicineTypeData[rowIndex])
                                },
                                {
                                    icon: <DeleteIcon sx={{ color: '#FF5454', minWidth: '20.09px', minHeight: '20.09px', height: 20.09, width: 29.09, '&:hover': { background: 'none', color: 'rgb(191, 44, 44)' } }} />,
                                    onClick: (rowIndex: any) => handleDeleteClick(medicineTypeData[rowIndex]?._id)
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
                <DeleteConfirmationModal
                    open={isDeleteModalOpen}
                    handleClose={handleDeleteModalClose}
                    handleConfirm={handleDeleteConfirm}
                />
            </Box>
        </Box>
    );
};

export default MedicineAdministration;
