import MedicineAdministration from '../../components/OPD/add-new-medicine/adminstration';
import ReferralForm from '../../components/OPD/refered-slip';
import Preloader from '../../components/Preloader';
import { Suspense, lazy } from 'react';



const OPDDashboard = lazy(() => import('../../pages/OPD/opd-dashboard'));
const AddPatientRecords = lazy(() => import('../../components/OPD/add-patient-record'));
const Diagnoses = lazy(() => import('../../pages/OPD/Admin/diagnosis'));
const Procedure = lazy(() => import('../../pages/OPD/Admin/Procedure'));
const Symptoms = lazy(() => import('../../pages/OPD/Admin/symptoms'));
const CaseDashboard = lazy(() => import('../../pages/OPD/Admin/case'));
const AddCaseForm = lazy(() => import('../../components/OPD/add-case-form'));
const Generic = lazy(() => import('../../pages/OPD/Admin/generic'));
const AddVitals = lazy(() => import('../../components/AddVitals'));
const MedicineSchedule = lazy(() => import('../../components/OPD/add-new-medicine/medicine-schedule'));
const AddNewMedicine = lazy(() => import('../../components/OPD/add-new-medicine'));
const FormDataEntry = lazy(() => import('../../components/Pathology/add-new-test/add-test'));
const Medicine = lazy(() => import('../../pages/OPD/Admin/medicine'));
const AddNew = lazy(() => import('../../pages/Super-Admin/add-new'));
const EmployeDepartment = lazy(() => import('../../pages/emplye-page/add-department'));
const EmpShift = lazy(() => import('../../components/employ/emp-shift'));
const AddNewAdmins = lazy(() => import('../../components/super-admin/add-new-admin'));
const AttachFile = lazy(() => import('../../components/employ/attatch-file'));

const AddNewTest = lazy(() => import('../../components/Pathology/add-new-test/index.tsx'));
const Home = lazy(() => import('../../pages/Home'))
const Patients = lazy(() => import('../../pages/Patients'))
const PatientRegistrationome = lazy(() => import('../../pages/reg-form/RegForms'))
const Employ = lazy(() => import('../../components/employ/BodyCard'))
const AddEmploye = lazy(() => import('../../pages/emplye-page/add-employe/add-employe-form'))
const EmployeCategory = lazy(() => import('../../pages/emplye-page/emp-category'))
const EmployeDetails = lazy(() => import('../../pages/emplye-page/add-employe/emp-detail-form'))
const UserReports = lazy(() => import('../../pages/reports/user-reports'))
const Insurances = lazy(() => import('../../pages/Insurance/Insurances'))
const EmpServices = lazy(() => import('../../components/AddEmpServices'))
const Companies = lazy(() => import('../../pages/Insurance/Companies/company-dashboard'))
const AddNewCompany = lazy(() => import('../../pages/Insurance/Companies/add-new'))
const PathologyDashboard = lazy(() => import('../../pages/pathalogy/dashboard'))
const RegisterPathology = lazy(() => import('../../pages/pathalogy/register'))
const PathologyRegistration = lazy(() => import('../../components/PathologyRegistration'))
const ReportTable = lazy(() => import('../../components/PathologyRegistration/report-table'))
const InsuranceServices = lazy(() => import('../../pages/Insurance/Services'))
const InsuranceReports = lazy(() => import('../../pages/Insurance/Insurances/insurance-reports'))
const SuperAdmin = lazy(() => import("../../pages/Super-Admin/dashboard"));
const NewAdmin = lazy(() => import("../../pages/Super-Admin/new-admin"));
const AddNewAdmin = lazy(() => import("../../components/super-admin/add-new-admin-form"));
const DefaultConfig = lazy(() => import('../../components/access-config/default-configs'));
const AccessConfigID = lazy(() => import('../../components/access-config'));
const AccessConfig = lazy(() => import('../../pages/AccessConfig'))
const UserProfile = lazy(() => import('../../pages/UserProfile'))
const ComponentWithTabs = lazy(() => import('../../components/Pathology/depart-units'));
const DeleteHistory = lazy(() => import('../../pages/DeleteHistory'));
const Analysis = lazy(() => import('../../pages/pathalogy/analysis'));
const TestResult = lazy(() => import('../../pages/pathalogy/test-results/test-list'));
const PRRE = lazy(() => import('../../pages/pathalogy/PR&RE'))
const DepartmentsAndLocations = lazy(() => import('../../pages/Departments&Locations'))
const StockManagement = lazy(() => import('../../pages/StockManagement'));
const AddAsset = lazy(() => import('../../pages/AddAssets'));
const AddTests = lazy(() => import('../../pages/pathalogy/test-results/test-list/add-tests'));
const NotFound = lazy(() => import('../../pages/404'));
const PrivatePaths = [

  {
    path: '/super-admin-dashboard',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <SuperAdmin />
      </Suspense>
    )
  },
  {
    path: '/register-new-admin',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewAdmin />
      </Suspense>
    )
  },
  {
    path: '/register-new-admin/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewAdmin />
      </Suspense>
    )
  },
  {
    path: '/new-admins',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewAdmins />
      </Suspense>
    )
  },
  {
    path: '/new-admins/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewAdmins />
      </Suspense>
    )
  },
  {
    path: '/all-admins',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <NewAdmin />
      </Suspense>
    )
  },



  {
    path: '/appointment-dashboard',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Home />
      </Suspense>
    )
  },
  // {
  //   path: '/update-patient',
  //   Content: () => (
  //     <Suspense fallback={<Preloader/>}>
  //       <Patients />
  //     </Suspense>
  //   )
  // },
  {
    path: '/patient-registration',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <PatientRegistrationome />
      </Suspense>
    )
  },
  {
    path: '/patient-registration:appointment',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <PatientRegistrationome />
      </Suspense>
    )
  },
  {
    path: '/employe',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Employ />
      </Suspense>
    )
  },

  {
    path: '/employe-category',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <EmployeCategory />
      </Suspense>
    )
  },
  {
    path: '/employe-add',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddEmploye />
      </Suspense>
    )
  },
  {
    path: '/employe-add/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddEmploye />
      </Suspense>
    )
  },
  {
    path: '/add-shift/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <EmpShift />
      </Suspense>
    )
  },
  {
    path: '/add-employe-details/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <EmployeDetails />
      </Suspense>
    )
  },
  {
    path: '/add-department',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <EmployeDepartment />
      </Suspense>
    )
  },
  {
    path: '/user-reports',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <UserReports />
      </Suspense>
    )
  },
  {
    path: '/insurances',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Insurances />
      </Suspense>
    )
  },
  {
    path: '/companies',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Companies />
      </Suspense>
    )
  },
  {
    path: '/attatch-file/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AttachFile />
      </Suspense>
    )
  },
  {

    path: '/emp-services/:empId/:name',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        < EmpServices />
      </Suspense>
    )
  },
  {
    path: '/add-new-company',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewCompany />

      </Suspense>
    )
  },

  {

    path: '/insurance-services',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <InsuranceServices />

      </Suspense>
    )
  },
  {

    path: '/report-table',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <ReportTable />
      </Suspense>
    )
  },
  {

    path: '/insurance-reports',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <InsuranceReports />
      </Suspense>
    )
  },
  {

    path: '/access-config',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AccessConfig />
      </Suspense>
    )
  },
  {

    path: '/user-access/:id/:name',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AccessConfigID />
      </Suspense>
    )
  },
  {
    path: '/default-config',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <DefaultConfig />
      </Suspense>
    )
  },
  {
    path: '/',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <UserProfile />
      </Suspense>
    )
  },
  {
    path: '/delete-history',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <DeleteHistory />
      </Suspense>
    )
  },
  // Pathology  
  {
    path: '/pathology',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <PathologyDashboard />

      </Suspense>
    )
  },
  {
    path: '/add-new-test',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewTest />

      </Suspense>
    )
  },
  {
    path: '/add-new-test/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewTest />

      </Suspense>
    )
  },
  {
    path: '/depart-units',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <ComponentWithTabs />

      </Suspense>
    )
  },
  {
    path: '/depart-units-1',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <FormDataEntry />

      </Suspense>
    )

  },
  {
    path: '/pathology-register',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <RegisterPathology />
      </Suspense>
    )
  },
  {
    path: '/pathology-registration',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <PathologyRegistration />
      </Suspense>
    )
  },
  {
    path: '/patient-registration-and-test-entry',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <PRRE />
      </Suspense>
    )
  },
  {
    path: '/patient-registration-and-test-entry/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <PRRE />
      </Suspense>
    )
  },

  {
    path: '/departments-and-locations',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <DepartmentsAndLocations />
      </Suspense>
    )
  },
  {
    path: '/stock-management',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <StockManagement />
      </Suspense>
    )
  },
  {
    path: '/add-assets',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddAsset />
      </Suspense>
    )
  },
  {

    path: '/pathology-analysis',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Analysis />
      </Suspense>
    )
  },
  {

    path: '/add-test-result/:testId/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddTests />
      </Suspense>
    )
  },
  {

    path: '/test-result',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <TestResult />
      </Suspense>
    )
  },

  // // // OPD 

  {

    path: '/opd-admin',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Medicine />
      </Suspense>
    )
  },
  {

    path: '/add-new-medicine',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddNewMedicine />
      </Suspense>
    )
  },
  {
    path: '/opd-dashboard',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <OPDDashboard />
      </Suspense>
    )
  },
  {

    path: '/add-patient-record/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddPatientRecords />
      </Suspense>
    )
  },
  // Diagnoses
  {

    path: '/diagnosis',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Diagnoses />
      </Suspense>
    )
  },
  {

    path: '/procedure',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Procedure />
      </Suspense>
    )
  },
  {

    path: '/symptoms',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Symptoms />
      </Suspense>
    )
  },
  {

    path: '/case-dashboard',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <CaseDashboard />
      </Suspense>
    )
  },
  {
    path: '/add-case',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddCaseForm />
      </Suspense>
    )
  },
  {
    path: '/add-case/:id',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddCaseForm />
      </Suspense>
    )
  },
  // {
  {

    path: '/generic',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <Generic />
      </Suspense>
    )
  },
  {
    path: '/add-vitals',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <AddVitals />
      </Suspense>
    )
  },
  {
    path: '/add-schedule',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <MedicineSchedule />
      </Suspense>
    )
  },
  {
    path: '/adminstration',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <MedicineAdministration />
      </Suspense>
    )
  },

  {
    path: '/referal-form',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <ReferralForm />
      </Suspense>
    )
  },

  // 404 Page
  {
    path: '*',
    Content: () => (
      <Suspense fallback={<Preloader />}>
        <NotFound />
      </Suspense>
    )
  }

];
export default PrivatePaths;

