import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
    Paper,
    Button,
    Typography,
    Grid,
    Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';


const ReferralForm = () => {

    const [patientId, setPatientId] = useState('');
    const [patientStatus, setPatientStatus] = useState('');
    const [dob, setDob] = useState('');
    const [age, setAge] = useState<any>('');
    const [mrn, setMrn] = useState<any>('');
    const [gender, setGender] = useState('');
    const [date, setDate] = useState('');
    const [timeIn, setTimeIn] = useState('');
    const [timeOut, setTimeOut] = useState('');
    const [reasonOfReferring, setReasonOfReferring] = useState('');
    const [vehicleUsed, setVehicleUsed] = useState('Own');
    const [attendantName, setAttendantName] = useState('');
    const [relationWithPatient, setRelationWithPatient] = useState('');
    const [referredBy, setReferredBy] = useState('');
    const [referredTo, setReferredTo] = useState('');
    const [investigationAdvised, setInvestigationAdvised] = useState('');

    // Calculate age based on DOB
    const calculateAge = (dob: string | number | Date) => {
        if (!dob) return;
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        setAge(age);
    };

    const handleDobChange = (event: { target: { value: any; }; }) => {
        const selectedDate = event.target.value;
        setDob(selectedDate);
        calculateAge(selectedDate);
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        // Prepare data to send to the API
        const formData = {
            patientId,
            patientStatus,
            dob,
            age,
            mrn,
            gender,
            date,
            timeIn,
            timeOut,
            reasonOfReferring,
            vehicleUsed,
            attendantName,
            relationWithPatient,
            referredBy,
            referredTo,
            investigationAdvised,
        };

        // Example URL, replace with your actual API endpoint
        const apiUrl = `${process.env.REACT_APP_OPD_API_URL}/referral/createRefferalForm`;

        try {
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    // Add any necessary headers here
                },
            });

            // Handle successful API response here
            toast.success(response.data.message)
            console.log('Form data sent successfully:', response.data);

            // Optionally clear form fields after successful submission
            clearFormFields();
        } catch (error: any) {
            toast.error(error.message)
            console.error('Error sending form data:', error.message);
            // Handle error state or display a message to the user
        }
    };

    const clearFormFields = () => {
        // Clear all form fields after successful submission
        setPatientId('');
        setPatientStatus('');
        setDob('');
        setAge('');
        setMrn('');
        setGender('');
        setDate('');
        setTimeIn('');
        setTimeOut('');
        setReasonOfReferring('');
        setVehicleUsed('Own');
        setAttendantName('');
        setRelationWithPatient('');
        setReferredBy('');
        setReferredTo('');
        setInvestigationAdvised('');
    };

    return (
        <Box sx={{ background: '#fff' }}>
            <Typography variant="h6" gutterBottom>
                Referral Form
            </Typography>
            <Box p={2}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label="Patient Name"
                                variant="outlined"
                                size='small'
                                fullWidth
                                value={patientId}
                                onChange={(e) => setPatientId(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl variant="outlined" fullWidth size='small'>
                                <InputLabel>Patient Status</InputLabel>
                                <Select
                                    value={patientStatus}
                                    onChange={(e) => setPatientStatus(e.target.value)}
                                    label="Patient Status"
                                >
                                    <MenuItem value="opd">OPD</MenuItem>
                                    <MenuItem value="admitted">ADMITTED</MenuItem>
                                    <MenuItem value="referred ">REFERRED</MenuItem>
                                    <MenuItem value="emergency ">EMERGENCY </MenuItem>
                                    <MenuItem value="death ">DEATH</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField size='small'
                                label="MRN No"
                                variant="outlined"
                                fullWidth
                                value={mrn}
                                onChange={(e) => setMrn(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={8} sm={4} md={3}>
                            <TextField size='small'
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                                fullWidth
                                value={dob}
                                onChange={handleDobChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2} md={1}>
                            <TextField size='small'
                                label="Age (Years)"
                                variant="outlined"
                                fullWidth
                                value={age}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl component="fieldset" >
                                <FormLabel component="legend" sx={{ fontSize: '14px' }}>Gender</FormLabel>
                                <RadioGroup
                                    row
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <FormControlLabel
                                        value="male"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '14px',
                                            },
                                        }} />}
                                        label="Male"
                                    />
                                    <FormControlLabel
                                        value="female"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '14px',
                                            },
                                        }} />}
                                        label="Female"
                                    />
                                    <FormControlLabel
                                        value="other"
                                        control={<Radio sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: '14px',
                                            },
                                        }} />}
                                        label="Other"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} sm={6} md={4}>
                            <TextField size='small'
                                label="Date"
                                type="date"
                                variant="outlined"
                                fullWidth
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField size='small'
                                label="Time In"
                                type="time"
                                variant="outlined"
                                fullWidth
                                value={timeIn}
                                onChange={(e) => setTimeIn(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField size='small'
                                label="Time Out"
                                type="time"
                                variant="outlined"
                                fullWidth
                                value={timeOut}
                                onChange={(e) => setTimeOut(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField size='small'
                                    label="Reason of Referring"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={2}
                                    value={reasonOfReferring}
                                    onChange={(e) => setReasonOfReferring(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl variant="outlined" fullWidth
                                    size='small'
                                >
                                    <InputLabel>Vehicle Used</InputLabel>
                                    <Select
                                        value={vehicleUsed}
                                        onChange={(e) => setVehicleUsed(e.target.value)}
                                        label="Vehicle Used"
                                    >
                                        <MenuItem value="Own">Own</MenuItem>
                                        <MenuItem value="Rescue">RESCUE</MenuItem>
                                        <MenuItem value="Local Transport">LOCAL_TRANSPORT</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    label="Attendant Name"
                                    variant="outlined"
                                    size='small'
                                    fullWidth
                                    value={attendantName}
                                    onChange={(e) => setAttendantName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    label="Relationship with Patient"
                                    variant="outlined"
                                    fullWidth
                                    size='small'
                                    value={relationWithPatient}
                                    onChange={(e) => setRelationWithPatient(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    size='small'
                                    label="Referred By"
                                    variant="outlined"
                                    fullWidth
                                    value={referredBy}
                                    onChange={(e) => setReferredBy(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    size='small'
                                    label="Referred To"
                                    variant="outlined"
                                    fullWidth
                                    value={referredTo}
                                    onChange={(e) => setReferredTo(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    size='small'
                                    label="Investigation Advised"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={investigationAdvised}
                                    onChange={(e) => setInvestigationAdvised(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={2} display='flex' justifyContent='flex-end'>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
};

export default ReferralForm;
