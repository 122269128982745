import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import { useAuth } from '../../../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';
import { Logout, Menu } from '@mui/icons-material';
import logo from '../../../assets/SVG/logo.png';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from '../../../redux/reducers/profileSlice';
import { fetchProjectProfileData } from '../../../redux/reducers/projectProfileSlice';
import socket from '../../../socket';
import { SocketResponse } from '../../../types';
interface HeaderProps {
  drawerToggler: () => void;
}
const Header: React.FC<HeaderProps> = ({ drawerToggler }) => {
  const { logout, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const profile = useSelector((state: any) => state.profileSlice.profile)
  const loading = useSelector((state: any) => state.profileSlice.loading);
  const projectProfile = useSelector((state: any) => state.projectProfileSlice.projectProfile)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchProfileData() as any)
    dispatch(fetchProjectProfileData() as any);
  }, [])
  useEffect(()=>{
    localStorage.setItem('projectOP',profile?.data?.projectId);
  },[loading])
  useEffect(() => {
        
    socket.on('connect', () => {
      console.log('Connected to the server');
    });
    socket.on('diconnect', () => {
      console.log('Server is disconnected!');
    });

    socket.on(profile?.data?.projectId.toString(), (data:SocketResponse) => {
      console.log('profile data',data)
      if(data?.module === 'profile')
      {
        dispatch(fetchProjectProfileData() as any);
      }
    });

  

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off(profile?.data?.projectId.toString());
    };
  }, [socket]);
  const [imageError, setImageError] = useState<boolean>(false);

  const handleImageError = () => {
    setImageError(true);
  };
  const handleLogout = () => {
    if (isLoggedIn) {
      logout();
      navigate('/');
    }
  };
  return (

    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='open drawer'
            sx={{ mr: 2 }}
            onClick={() => drawerToggler()}
          >
            <Menu />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              margin: 'auto',
            }}
          >
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md:'block' } }}
            >

              {projectProfile?.hospitalLogoUrl ? <img src={projectProfile?.hospitalLogoUrl} alt={projectProfile?.hospitalName + ' logo'} style={{ height: '40px', cursor: 'pointer' }} onClick={() => navigate('/')} onError={handleImageError} /> : <img src={logo} style={{ height: '40px', cursor: 'pointer' }} onClick={() => navigate('/')} />}

            </Typography>
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block', sm:'none' }, textTransform: 'uppercase' }}
            >
              {projectProfile?.hospitalName}
            </Typography>
            <Typography variant='h6' sx={{ fontSize: { lg: '1.25rem', md: '1rem', xs: '0.8rem' }, textTransform: 'capitalize' }}>
              {loading ? '' : profile?.data?.userType.includes('admin') ? "Welcome, " + profile?.data?.firstName + ' ' + profile?.data?.lastName : "Welcome, " + profile?.data?.fullName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
              {/* <PrimarySearchBar /> */}
              {isLoggedIn && (
                <Button
                  onClick={handleLogout}
                  startIcon={<Logout />}
                  sx={{
                    background: 'white',
                    fontWeight: '500',
                    py: '6px',
                    marginLeft: '20px',
                    ':hover': {
                      bgcolor: 'gray',
                      color: 'white',
                    },
                  }}
                  variant='outlined'
                >
                  Logout
                </Button>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
export default Header;
